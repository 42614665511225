import React from "react";
import Layout from "../layouts/Layout";
import ContentSubLayout from "../layouts/ContentSubLayout";
import CONSTANTS from "../utils/constants";

export default () => {
  return (
    <Layout className="page-content page-cumulus" showSMMenuLink={false}>
      <ContentSubLayout
        pageReference={CONSTANTS.CONTENT_PAGE_REFERENCES.CHAUFFE_EAU}
      />
    </Layout>
  );
};
